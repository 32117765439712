import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { HttpClient,HttpClientModule } from '@angular/common/http';

//translation
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SearchComponent } from './components/search/search.component';
import { ProductoComponent } from './components/producto/producto.component';
import { ProductCellComponent } from './components/product-cell/product-cell.component';
import { DecowoodService } from './services/decowood.service';
import { GuiasComponent } from './components/guias/guias.component';
import { DomseguroPipe } from './pipes/domseguro.pipe';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { TranslationComponent } from './tools/translation/translation.component';
import { LanguageService } from './services/language.service';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import  LocaleEs from '@angular/common/locales/es';
import  LocaleFr from '@angular/common/locales/fr';
import { InstruccionesComponent } from './components/guias/instrucciones/instrucciones.component';
import { CompraComponent } from './components/guias/compra/compra.component';
import { SostenibleComponent } from './components/guias/sostenible/sostenible.component';
import { MantenimientoComponent } from './components/guias/mantenimiento/mantenimiento.component';

registerLocaleData(LocaleEs);
registerLocaleData(LocaleFr);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    SearchComponent,
    ProductoComponent,
    ProductCellComponent,
    GuiasComponent,
    DomseguroPipe,
    LoadingComponent,
    TranslationComponent,
    InstruccionesComponent,
    CompraComponent,
    SostenibleComponent,
    MantenimientoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    DecowoodService,
    SearchComponent,
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (LanguageService) => LanguageService.getLanguage()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
