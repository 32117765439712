import { ThrowStmt } from '@angular/compiler';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor ( private translate: TranslateService, private language: LanguageService){
    this.setAppLanguage();
  }

  setAppLanguage(){
    this.translate.setDefaultLang(this.language.getLanguage());
    this.translate.use(this.language.getLanguage());
  }


  title = 'spa';
}
