import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DecowoodService } from '../../services/decowood.service';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styles: [
  ]
})
export class ProductoComponent {
  @Input() producto: any = {};
  //producto: any = {};
  //loadingProduct: boolean;

  constructor(private router: ActivatedRoute,
              private decowood: DecowoodService) {

        /*this.loadingProduct = true;
        
        this.router.params.subscribe(params =>{
          this.getProduct( params['id'] );
        });*/
        

               }
  
   /*getProduct(id: number){
     this.loadingProduct = true;

     this.decowood.getProducto(id).subscribe(producto => {
        this.producto = producto;
        this.loadingProduct = false;
     });
   } */

}
