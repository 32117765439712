<div class="bienvenida" *ngIf="!true">
    <h1 class="font-weight-bold" style="text-align: center; ">{{"Te damos la bienvenida" | translate}}.</h1>

    <div class="guias row">
        <div class="col-6 ">
            <div class="guias_first guias_img_container">
                <img class="guias_img" src="assets/img/imatge2.png" alt="">
                <h4>Guía de instrucciones >></h4>
            </div>
        </div>
        <div class="col-6 ">
            <div class="guias_first guias_img_container">
                <img class="guias_img" src="assets/img/imatge2.png" alt="">
                <h4>Guía de instrucciones >></h4>
            </div>
        </div>
        <div class="col-6">
            <div class="guias_img_container">
                <img class="guias_img" src="assets/img/imatge2.png" alt="">
                <h4>Guía de compra >></h4>
            </div>
        </div>
        <div class="col-6">
            <div class="guias_img_container">
                <img class="guias_img" src="assets/img/imatge2.png" alt="">
                <h4>Guía de compra >></h4>
            </div>
        </div>
    </div>

</div>
