<app-search></app-search>
<div class="pt-2 container">
  <div class="row">
    <div class="col-xs-12 col-md-12 mb-4"><h2><b>{{"Guía de mantenimiento." | translate}}</b></h2>
      <p>{{"Si quieres que tus muebles tengan una larga vida y que estén como el primer día no dudes en seguir nuestras recomendaciones." | translate}}</p></div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"Recomendaciones generales" | translate}}</b></p>
      <p>{{"Nuestra primera recomendación es evitar arrastrar los muebles y sillones, puesto que ocasiona daños a la estructura, costuras y patas, es mejor levantarlos. " | translate}}</p>
      <p>{{"Limpia tu mueble regularmente y mantenlo así, ¡te lo agradecerá! Por otro lado, utilizar productos de limpieza no contaminantes, ayúdanos a cuidar el medio ambiente. Debes evitar en su mayor medida la exposición a los rayos del sol, ya que podría provocar una decoloración del producto. " | translate}}</p>


    </div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"Madera" | translate}}</b></p>
      <p>{{"Limpia regularmente el polvo con un paño húmedo para evitar la acumulación de éste, ya que puede hacer perder brillo a los muebles. Para zonas de difícil acceso puedes usar brochas con cerdas naturales. " | translate}}</p>
      <p>{{"La madera es un material resistente, sin embargo según su acabado evita los cambios bruscos de temperatura y de humedad ya que podrían hacer que el producto se dilate y posteriormente descomponga. " | translate}}</p>
      <p>{{"No se deben utilizar productos de limpieza abrasivos o cera como por ejemplo productos que puedan contener acetona, alcohol, amoniaco, lejía… Acabarían retirando el esmalte del producto. Utiliza posavasos debajo de objetos calientes." | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <img class="img-fluid" src="../../../../assets/img/Mantenimiento-min.png" alt="instrucciones">
    </div>

  </div>
  <div class="m-4"></div>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <p><b>{{"Tapizados" | translate}}</b></p>
      <p>{{"Para mantener tu tapizado limpio y mantener su apariencia se debe limpiar de manera periódica para así evitar la acumulación de suciedad. Para hacerlo correctamente utiliza una solución de agua con jabón líquido neutro, frota suavemente en la mancha con un cepillo de cerdas suaves y pasa un paño humedecido sobre lo anterior. Finalmente secalo con un paño suave hasta que quede totalmente seco. " | translate}}</p>
      <p>{{"En caso de manchar por accidente el producto limpiar se debe limpiar con rapidez, como hemos indicado anteriormente, para evitar que la mancha quede permanentemente. No se debe dejar que el agua llegue a empapar la espuma bajo el tapizado, ya que podría provocar daños. " | translate}}</p>
      <p>{{"Se deben evitar los cambios bruscos de temperatura y de humedad en tus tapizados. El calor excesivo provoca que las telas se vuelvan frágiles, quebradizas y sean más propensas a desgastarse. La humedad puede originar la aparición de moho. No se deben utilizar productos como la lejía, esponjas o bayetas abrasivas ni tampoco utilizar betún, aguarrás o quitamanchas, ya que podrían dañar el material. " | translate}}</p>

    </div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"Fibras naturales" | translate}}</b></p>
      <p>{{"Las fibras naturales tienen una ventaja, y es que son muy resistentes y durables en el tiempo incluso frente al sol o la lluvia si lo tienes en exterior. Aspíralas y sacúdelas a menudo para evitar que el polvo se acumule entre sus fibras. Te recomendamos poder quitar el polvo con un plumero y bajo ningún concepto utilizar soluciones acuosas para limpiar el producto ya que lo absorbería y marcaría el color. Si se derrama algún líquido, elimínalo al instante. Si es agua use papel o secador de pelo." | translate}}</p>
    </div>
  </div>

</div>


