import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompraComponent } from './components/guias/compra/compra.component';
import { GuiasComponent } from './components/guias/guias.component';
import { InstruccionesComponent } from './components/guias/instrucciones/instrucciones.component';
import { MantenimientoComponent } from './components/guias/mantenimiento/mantenimiento.component';
import { SostenibleComponent } from './components/guias/sostenible/sostenible.component';
import { ProductoComponent } from './components/producto/producto.component';
import { SearchComponent } from './components/search/search.component';

const routes: Routes = [
  {path: '', component:SearchComponent},
      {
        path: 'guia-instrucciones', // child route path
        component: InstruccionesComponent // child route component that the router renders
      },
      {
        path: 'guia-compra',
        component: CompraComponent, // another child route component that the router renders
      },
      {
        path: 'guia-compra-sostenible',
        component: SostenibleComponent, // another child route component that the router renders
      },
      {
        path: 'guia-mantenimiento',
        component: MantenimientoComponent, // another child route component that the router renders
      },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    useHash : true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
